import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { RotatingLines } from  'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { FaCheck } from "react-icons/fa";
import {FaRegWindowClose} from "react-icons/fa"
import $ from 'jquery'; 

const Inventory = () => {

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
   
    const bold = {
        fontWeight : '600'
    }
    useEffect(() => {
        setLoading(true)
        const url = process.env.REACT_APP_BASE_URL + '/api/v2/list.php';
        fetch(url, {
            method : "POST",
            body : JSON.stringify()
        })
        .then(response => response.json())
        .then(data => setList(data.results))
        .then(() => {
            setLoading(false)
            const caller = setInterval(
                () => {
                    if($('#file_export').length > 0 ){
                        if(!$.fn.dataTable.isDataTable("#file_export")){
                            $('#file_export').DataTable({
                                "dom": "lifrtp",
                                "order": [[ 0, "asc" ]]
                            }) 
                        }
                        clearInterval(caller)
                    }
                },
                100
            )
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
    }, []);
 
    const deleteBreed = (e, id, i) => {
        e.preventDefault();
        if (window.confirm('Are you sure you want to delete the breed ' + id + '?')){
            const endpoint = process.env.REACT_APP_BASE_URL + '/api/v2/delete.php';
            fetch(endpoint, {
                "method": "POST",
                "body": JSON.stringify({id : id})
            })
            .then(response => response.json())
            .then(response => {     
                if(response.action === "failed"){
                    toast.error(response.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                        theme: "colored"
                    });
                }else{
                    toast.success(response.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                        theme: "colored"
                    });
                    list.splice(i, 1);
                    setList(list);
                    setTimeout(() => {
                        window.location.reload(false);
                    }, 2500);
                    
                    
                }
            })
            .catch(err => {
                console.log(err);
            });
        }else{
            
        }
    }

    return ( 
        <div className="table-responsive">  
            <RotatingLines
                strokeColor="rgb(143, 63, 14)"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loading}
            />
            <ToastContainer />  
            {
                loading ? '' :  
            <table id="file_export" className="table table-striped table-bordered display small">
            <thead>
                <tr>
                    <th></th>
                    <th>ID</th>
                    <th>image</th>
                    <th>Name</th>
                    <th>Gallery</th>
                    <th>QA</th>
                    <th>Similar Breeds</th>
                    <th>Creation date</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {
                list.map((item, i) => (
                    <tr key={i} >
                        <td>{i+1}</td>
                        <td>
                            <div className="image-dog"><img width="50" height="50" src={"https://mystart.mystartcdn.com/assets-new-tab/pawtastictab/breeds/" + item.id + ".jpg"}/></div>
                        </td>
                        <td style={bold}>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.gallery}</td>
                        <td>
                         { item.qa.length > 0 ? 
                            <FaCheck size={15} color={"green"} />
                          : 
                            <FaCheck size={15} color={"red"} />
                        }
                        </td>
                        <td>
                            <div style={{'display':'flex',justifyContent:'space-between', alignItems:'center'}}>
                            {
                            item.similar_breeds !== null && item.similar_breeds.map((item, i) => (
                                <div className="image-dog-small" key={i}>
                                    <div className="similar-name">{item.id}</div>
                                    <img width="60px" height="60px" key={i} src={"https://mystart.mystartcdn.com/assets-new-tab/pawtastictab/breeds/" + item.id + ".jpg"}/>
                                </div>
                            ))
                            }
                           </div>
                        </td>
                        <td>{item.createdAt}</td>
                        <td><Link to={"/edit/" + item.id}><button type="button" className="btn btn-info">Edit</button></Link></td>
                        <td><button type="button" className="btn btn-light"><span className="delete" onClick={(e) => deleteBreed(e, item.id, i)}>&nbsp;</span></button></td>
                    </tr>              
                ))    
            }
            </tbody>
            <tfoot>
                <tr>
                    <th></th>
                    <th>ID</th>
                    <th>image</th>
                    <th>Name</th>
                    <th>Gallery</th>
                    <th>QA</th>
                    <th>Similar Breeds</th>
                    <th>Creation date</th>
                    <th></th>
                    <th></th>
                </tr>
            </tfoot>
        </table>
        }
    </div>
    );
}



export default Inventory;