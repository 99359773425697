import React from "react";
import Header from "../components/Header";
import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import List from "../components/List";
import Ratings from "../components/Ratings";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FcPlus, FcMinus } from 'react-icons/fc';
import { FiMinusCircle } from 'react-icons/fi';


const  Edit = () => {

    const { id } = useParams();
    const [info, setInfo] = useState(0);
    const [description, setDescription] = useState('');
    const [gallery, setGallery] = useState('');
    const [name, setName] = useState('');

    const [familyDescription, setFamilyDescription] = useState('');
    const [physicalDescription, setPhysicalDescription] = useState('');
    const [socialDescription, setSocialDescription] = useState('');
    const [personalityDescription, setPersonalityDescription] = useState('');

    const [newQuestion, setNewQuestion] = useState('');
    const [newAnswer, setNewAnswer] = useState('');
    const [newSource, setNewSource] = useState('');
    const [newLink, setNewLink] = useState('');
    
    const [showNewQA, setShowNewQA] = useState(false)

    const [allTexts, setAllTexts] = useState('');
    const [allQAs, setAllQAs] = useState([]);
    const [allSimilarBreeds, setAllSimilarBreeds] = useState('');
    const [minHeight, setMinHeight] = useState();
    const [maxHeight, setMaxHeight] = useState();
    const [minWeight, setMinWeight] = useState();
    const [maxWeight, setMaxWeight] = useState();
    const [minLifeSpan, setMinLifeSpan] = useState();
    const [maxLifeSpan, setMaxLifeSpan] = useState();
    const [affection, setAffection] = useState();
    const [children, setChildren] = useState();
    const [otherdogs, setOtherDogs] = useState();

    const [shedding, setShedding] = useState();
    const [grooming, setGrooming] = useState();
    const [drooling, setDrooling] = useState();

    const [strangers, setStrangers] = useState();
    const [playful, setPlayful] = useState();
    const [protective, setProtective] = useState();
    const [adaptability, setAdaptability] = useState();

    const [trainability, setTrainability] = useState();
    const [energyLevel, setEnergyLevel] = useState();
    const [barkingLevel, setBarkingLevel] = useState();
    const [stimulationNeeds, setStimulationNeeds] = useState();

    const bold = {
        fontWeight : '600'
    }

    const handleFamilyDescription = event => {
        setFamilyDescription(event.target.value)
    }

    const handlePhysicalDescription = event => {
        setPhysicalDescription(event.target.value)
    }

    const handleSocialDescription = event => {
        setSocialDescription(event.target.value)
    }

    const handlePersonalityDescription = event => {
        setPersonalityDescription(event.target.value)
    }

    const handleName = event => {
        setName(event.target.value);
    }

    const handleMinHeight = event => {
        setMinHeight(event.target.value);
    }

    const handleMaxHeight = event => {
        setMaxHeight(event.target.value);
    }
   
    const handleMinWeight = event => {
        setMinWeight(event.target.value);
    }

    const handleMaxWeight = event => {
        setMaxWeight(event.target.value);
    }

    const handleMinLifeSpan = event => {
        setMinLifeSpan(event.target.value);
    }

    const handleMaxLifeSpan = event => {
        setMaxLifeSpan(event.target.value);
    }

    const handleAffection = event => {
        setAffection(event.target.value);
    }

    const handleChildren = event => {
        setChildren(event.target.value);
    }

    const handleOtherDogs = event => {
        setOtherDogs(event.target.value);
    }

    const handleShedding = event => {
        setShedding(event.target.value);
    }
    const handleGrooming = event => {
        setGrooming(event.target.value);
    }
    const handleDrooling = event => {
        setDrooling(event.target.value);
    }

    const handleStrangers = event => {
        setStrangers(event.target.value);
    }
    const handlePlayful = event => {
        setPlayful(event.target.value);
    }
    const handleProtective = event => {
        setProtective(event.target.value);
    }
    const handleAdaptability = event => {
        setAdaptability(event.target.value);
    }

    const handleTrainability = event => {
        setTrainability(event.target.value);
    }
    const handleEnergyLevel = event => {
        setEnergyLevel(event.target.value);
    }
    const handleBarkingLevel = event => {
        setBarkingLevel(event.target.value);
    }
    const handleStimulationNeeds = event => {
        setStimulationNeeds(event.target.value);
    }

    const handleSave = (event) => {
        event.preventDefault();
        const data = {
            breed : id,
            name : name,
            description : description,
            familyDescription : familyDescription,
            socialDescription : socialDescription,
            personalityDescription : personalityDescription,
            physicalDescription : physicalDescription,
            gallery : gallery,
            allText : allTexts,
            qa : allQAs,
            allSimilarBreedText : allSimilarBreeds,
            minHeight : minHeight,
			maxHeight : maxHeight,
			minWeight: minWeight,
			maxWeight : maxWeight,
            minLifeSpan : minLifeSpan,
			maxLifeSpan : maxLifeSpan,
			affection : affection,
            children : children,
            otherdogs : otherdogs,
            shedding : shedding,
            grooming : grooming,
            drooling : drooling,
            strangers : strangers,
            playful : playful,
            protective : protective,
            adaptability : adaptability,
            trainability : trainability,
            energyLevel : energyLevel,
            barkingLevel : barkingLevel,
            stimulationNeeds : stimulationNeeds,
        };
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/v2/edit.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
                //reset();
            }
        })
        .catch(err => {
            console.log(err);
        });
    };

    useEffect(() => {
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/v2/getInfo.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify({id : id})
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                console.log("Error");
            }else{
                setDescription(response.results.description);
                setGallery(response.results.gallery);
                setName(response.results.name);

                if(response.results.texts)
                    setAllTexts(response.results.texts.join('\n'));

                if(response.results.qa)
                    setAllQAs(response.results.qa);
            
                setAllSimilarBreeds(response.results.similar_breeds.join(',').replace(/[\n\r]/g, ''));
                setMinHeight(response.results.data.height.min);
                setMaxHeight(response.results.data.height.max);
                setMinWeight(response.results.data.weight.min);
                setMaxWeight(response.results.data.weight.max);
                setMinLifeSpan(response.results.data.life_span.min);
                setMaxLifeSpan(response.results.data.life_span.max);
                setAffection(response.results.data.traits_characteristics.family_life.affection);
                setChildren(response.results.data.traits_characteristics.family_life.children);
                setOtherDogs(response.results.data.traits_characteristics.family_life.other_dogs);
                setShedding(response.results.data.traits_characteristics.physical.shedding);
                setGrooming(response.results.data.traits_characteristics.physical.grooming);
                setDrooling(response.results.data.traits_characteristics.physical.drooling);
                setStrangers(response.results.data.traits_characteristics.social.strangers);
                setPlayful(response.results.data.traits_characteristics.social.playful);
                setProtective(response.results.data.traits_characteristics.social.protective);
                setAdaptability(response.results.data.traits_characteristics.social.adaptability);
                setTrainability(response.results.data.traits_characteristics.personality.trainability);
                setEnergyLevel(response.results.data.traits_characteristics.personality.energyLevel);
                setBarkingLevel(response.results.data.traits_characteristics.personality.barkingLevel);
                setStimulationNeeds(response.results.data.traits_characteristics.personality.stimulationNeeds);

                setInfo(1);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }, [info]);
 

    const handleText = event => {
        setAllTexts(event.target.value);
        if(event.target.value.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleQuestion = (event, i) => {
        setAllQAs([...allQAs.slice(0,i), {...allQAs[i], question:event.target.value}, ...allQAs.slice(i+1)]);
    };

    const handleResponse = (event, i) => {
        setAllQAs([...allQAs.slice(0,i), {...allQAs[i], answer:event.target.value}, ...allQAs.slice(i+1)]);
    };

    const handleSource = (event, i) => {
        setAllQAs([...allQAs.slice(0,i), {...allQAs[i], source:event.target.value}, ...allQAs.slice(i+1)]);
    };

    const handleLink = (event, i) => {
        setAllQAs([...allQAs.slice(0,i), {...allQAs[i], link:event.target.value}, ...allQAs.slice(i+1)]);
    };

    const handleSimilarBreeds = event => {
        setAllSimilarBreeds(event.target.value);
        if(event.target.value.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleDescription = event => {
        const desc = event.target.value;
        setDescription(desc);
            
        if(desc.length < 10){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleGallery = event => {
        const url = event.target.value;
        setGallery(url);
            
        if(url.length < 10){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const addNewQA = () => {
        setShowNewQA(!showNewQA)
    }


    const saveNewQA = () => {
        const data = {
            question : newQuestion,
            answer : newAnswer,
            source : newSource,
            link : newLink
        }
        setAllQAs([data, ...allQAs])
        setShowNewQA(false)
        setNewQuestion('')
        setNewAnswer('')
        setNewSource('')
        setNewLink('')
    }

    const deleteQuestion = (e, i) => {
        e.preventDefault()
        allQAs.splice(i, 1);
        setAllQAs([...allQAs])
    }

    return (
        <>
        <ToastContainer />
        <Header page="Edit"/> 
        {
            <div className="container-fluid small">
                <form style={{ marginTop:'30px' }}>
                    <div className="row">
                        <div className="col">
                            <div className="form-group mb-3">
                                <label style={bold}>Dog Breeds <span className="text-danger">*</span></label>
                                <div className="controls">
                                    <input type="text" data-testid="breedname"  className="form-control small" value={id} disabled/>
                                </div>
                            </div>

                            <div className="form-group mb-3">
                                <label style={bold}>Name</label>
                                <div className="controls">
                                    <input type="text" className="form-control small" value={name} onChange={handleName}/>
                                </div>
                            </div>
                        
                            <div className="form-group mb-3">
                                <label style={bold}>Description <span className="text-danger">*</span></label>
                                <input type="text" className="form-control small" id="description" value={description} onChange={handleDescription}/>
                                <div className="invalid-feedback">The description field is required (Min 10 characters)</div>
                                <div className="valid-feedback"></div>
                            </div>

                            <div className="form-group mb-3">
                                <label style={bold}>Gallery URL <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" data-testid="gallery" id="gallery" value={gallery} onChange={handleGallery}/>
                                <div className="invalid-feedback">The gallery URL field is required</div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                    </div>
            
                    <div className="row">
                        <div className="col">
                            <div className="form-group mb-3">
                                <label style={bold}>Texts</label>
                                {
                                    <div style={{ 'width':'100%', 'margin': '5px 0' }}>
                                        <textarea className="form-control small" id="text" value={allTexts} onChange={handleText}></textarea>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group mb-3" style={{ fontWeight:'500', fontSize:'15px', margin:'10px 0' }}>
                                <a href="#" onClick={addNewQA} style={{ textDecoration:'none' }}>Add new Q&A {showNewQA ? <FiMinusCircle size={20} color={"green"} /> : <FcPlus size={20} />}</a>
                            </div>
                            {showNewQA ? 
                            <div>
                                <div style={{margin:'20px 0', padding:'20px', background:'#41464b8c'}}>
                                    
                                    <div style={{ 'width':'100%', 'margin': '5px 0' }}>
                                        <label style={bold}>Question</label>
                                        <textarea className="form-control small" id="text" value={newQuestion} onChange={(e) => setNewQuestion(e.target.value)}></textarea>
                                    </div>
                                    <div style={{ 'width':'100%', 'margin': '5px 0' }}>
                                        <label style={bold}>Answer</label>
                                        <textarea className="form-control small" id="text" value={newAnswer} onChange={(e) => setNewAnswer(e.target.value)}></textarea>
                                    </div>
                                    <div style={{ 'width':'100%', 'margin': '5px 0' }}>
                                        <label style={bold}>Source</label>
                                        <textarea className="form-control small" id="text" value={newSource} onChange={(e) => setNewSource(e.target.value)}></textarea>
                                    </div>
                                    <div style={{ 'width':'100%', 'margin': '5px 0' }}>
                                        <label style={bold}>Source URL</label>
                                        <textarea className="form-control small" id="text" value={newLink} onChange={(e) => setNewLink(e.target.value)}></textarea>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div>
                                            <button type="button" onClick={saveNewQA} className="btn btn-primary">Save QA</button>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            : ''
                            }

                            <div className="form-group mb-3">
                                {
                                    allQAs.map((item, i) => (
                                        <div key={i} style={{margin:'20px 0', padding:'20px', background:'#ccc'}}>
                                            <button type="button" style={{margin:'10px 0', float:'right'}} onClick={(e) => deleteQuestion(e, i)} className="btn btn-primary">Delete Question {i+1}</button>
                                            <div style={{ 'width':'100%', 'margin': '5px 0' }}>
                                                <label style={bold}>Question {i+1}</label>
                                                <textarea className="form-control small" id="text" value={item.question} onChange={(e) => handleQuestion(e, i)}></textarea>
                                            </div>
                                            <div style={{ 'width':'100%', 'margin': '5px 0' }}>
                                                <label style={bold}>Answer {i+1}</label>
                                                <textarea className="form-control small" id="text" value={item.answer} onChange={(e) => handleResponse(e, i)}></textarea>
                                            </div>
                                            <div style={{ 'width':'100%', 'margin': '5px 0' }}>
                                                <label style={bold}>Source {i+1}</label>
                                                <textarea className="form-control small" id="text" value={item.source} onChange={(e) => handleSource(e, i)}></textarea>
                                            </div>
                                            <div style={{ 'width':'100%', 'margin': '5px 0' }}>
                                                <label style={bold}>Source URL {i+1}</label>
                                                <textarea className="form-control small" id="text" value={item.link} onChange={(e) => handleLink(e, i)}></textarea>
                                            </div>
                                        </div>
                                    ))
                                    
                                }
                            </div>
                        </div>
                    </div>              
                    
                    <div className="row">
                        <div className="col">
                            <div className="form-group mb-3">
                                <label style={bold}>Similar Breeds</label>
                                {
                                    <div style={{ 'width':'100%', 'margin': '5px 0' }}>
                                        <textarea className="form-control small" row="3" id="similarBreeds" value={allSimilarBreeds} onChange={handleSimilarBreeds}></textarea>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
            
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Min Height</label>
                                <div className="controls">
                                    <select
                                        name="minheight"
                                        id="minheight"
                                        className="form-select mb-3" 
                                        onChange={handleMinHeight} 
                                        value={minHeight}>
                                            <option value=""></option>
                                            <List />
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Max Height</label>
                                <div className="controls">
                                    <select
                                        name="maxheight"
                                        id="maxheight"
                                        className="form-select mb-3" 
                                        onChange={handleMaxHeight} 
                                        value={maxHeight}>
                                            <option value=""></option>
                                            <List />
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Min Weight</label>
                                <div className="controls">
                                    <select
                                        name="minweight"
                                        id="minweight"
                                        className="form-select mb-3" 
                                        onChange={handleMinWeight} 
                                        value={minWeight}>
                                            <option value=""></option>
                                            <List />
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Max Weight</label>
                                <div className="controls">
                                    <select
                                        name="maxweight"
                                        id="maxweight"
                                        className="form-select mb-3" 
                                        onChange={handleMaxWeight} 
                                        value={maxWeight}>
                                            <option value=""></option>
                                            <List />
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Min Life Span</label>
                                <div className="controls">
                                    <select
                                        name="minlifespan"
                                        id="minlifespan"
                                        className="form-select mb-3" 
                                        onChange={handleMinLifeSpan} 
                                        value={minLifeSpan}>
                                            <option value=""></option>
                                            <List />
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Max Life Span</label>
                                <div className="controls">
                                    <select
                                        name="maxlifespan"
                                        id="maxlifespan"
                                        className="form-select mb-3" 
                                        onChange={handleMaxLifeSpan} 
                                        value={maxLifeSpan}>
                                            <option value=""></option>
                                            <List />
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="characteristic_title">Family Life</div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Description</label>
                                <textarea className="form-control small" row="2" id="familyDescription" value={familyDescription} onChange={handleFamilyDescription}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            
                            <div className="form-group">
                                <label style={bold}>Affection</label>
                                <div className="controls">
                                    <select
                                        name="affection"
                                        id="affection"
                                        className="form-select mb-3" 
                                        onChange={handleAffection} 
                                        value={affection}>
                                            <option value=""></option>
                                            {
                                                Ratings.map((item, i) => (
                                                    <option key={item}>{item}</option>
                                                ))
                                            }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Children</label>
                                <div className="controls">
                                    <select
                                        name="children"
                                        id="children"
                                        className="form-select mb-3" 
                                        onChange={handleChildren} 
                                        value={children}>
                                            <option value=""></option>
                                            {
                                                Ratings.map((item, i) => (
                                                    <option key={item}>{item}</option>
                                                ))
                                            }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Other dogs</label>
                                <div className="controls">
                                    <select
                                        name="otherdogs"
                                        id="otherdogs"
                                        className="form-select mb-3" 
                                        onChange={handleOtherDogs} 
                                        value={otherdogs}>
                                            <option value=""></option>
                                            {
                                                Ratings.map((item, i) => (
                                                    <option key={item}>{item}</option>
                                                ))
                                            }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="characteristic_title">Physical</div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Description</label>
                                <textarea className="form-control small" row="2" id="physicalDescription" value={physicalDescription} onChange={handlePhysicalDescription}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Shedding</label>
                                <div className="controls">
                                    <select
                                        name="shedding"
                                        id="shedding"
                                        className="form-select mb-3" 
                                        onChange={handleShedding} 
                                        value={shedding}>
                                            <option value=""></option>
                                            {
                                                Ratings.map((item, i) => (
                                                    <option key={item}>{item}</option>
                                                ))
                                            }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Grooming</label>
                                <div className="controls">
                                    <select
                                        name="grooming"
                                        id="grooming"
                                        className="form-select mb-3" 
                                        onChange={handleGrooming} 
                                        value={grooming}>
                                            <option value=""></option>
                                            {
                                                Ratings.map((item, i) => (
                                                    <option key={item}>{item}</option>
                                                ))
                                            }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Drooling</label>
                                <div className="controls">
                                    <select
                                        name="drooling"
                                        id="drooling"
                                        className="form-select mb-3" 
                                        onChange={handleDrooling} 
                                        value={drooling}>
                                            <option value=""></option>
                                            {
                                                Ratings.map((item, i) => (
                                                    <option key={item}>{item}</option>
                                                ))
                                            }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="characteristic_title">Social</div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Description</label>
                                <textarea className="form-control small" row="2" id="socialDescription" value={socialDescription} onChange={handleSocialDescription}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Strangers</label>
                                <div className="controls">
                                    <select
                                        name="strangers"
                                        id="strangers"
                                        className="form-select mb-3" 
                                        onChange={handleStrangers} 
                                        value={strangers}>
                                            <option value=""></option>
                                            {
                                                Ratings.map((item, i) => (
                                                    <option key={item}>{item}</option>
                                                ))
                                            }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Playful</label>
                                <div className="controls">
                                    <select
                                        name="playful"
                                        id="playful"
                                        className="form-select mb-3" 
                                        onChange={handlePlayful} 
                                        value={playful}>
                                            <option value=""></option>
                                            {
                                                Ratings.map((item, i) => (
                                                    <option key={item}>{item}</option>
                                                ))
                                            }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Protective</label>
                                <div className="controls">
                                    <select
                                        name="protective"
                                        id="protective"
                                        className="form-select mb-3" 
                                        onChange={handleProtective} 
                                        value={protective}>
                                            <option value=""></option>
                                            {
                                                Ratings.map((item, i) => (
                                                    <option key={item}>{item}</option>
                                                ))
                                            }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Adaptability</label>
                                <div className="controls">
                                    <select
                                        name="adaptability"
                                        id="adaptability"
                                        className="form-select mb-3" 
                                        onChange={handleAdaptability} 
                                        value={adaptability}>
                                            <option value=""></option>
                                            {
                                                Ratings.map((item, i) => (
                                                    <option key={item}>{item}</option>
                                                ))
                                            }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="characteristic_title">Personality</div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Description</label>
                                <textarea className="form-control small" row="2" id="personalityDescription" value={personalityDescription} onChange={handlePersonalityDescription}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Trainability</label>
                                <div className="controls">
                                    <select
                                        name="trainability"
                                        id="trainability"
                                        className="form-select mb-3" 
                                        onChange={handleTrainability} 
                                        value={trainability}>
                                            <option value=""></option>
                                            {
                                                Ratings.map((item, i) => (
                                                    <option key={item}>{item}</option>
                                                ))
                                            }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Energy Level</label>
                                <div className="controls">
                                    <select
                                        name="energyLevel"
                                        id="energyLevel"
                                        className="form-select mb-3" 
                                        onChange={handleEnergyLevel} 
                                        value={energyLevel}>
                                            <option value=""></option>
                                            {
                                                Ratings.map((item, i) => (
                                                    <option key={item}>{item}</option>
                                                ))
                                            }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Barking Level</label>
                                <div className="controls">
                                    <select
                                        name="barkingLevel"
                                        id="barkingLevel"
                                        className="form-select mb-3" 
                                        onChange={handleBarkingLevel} 
                                        value={barkingLevel}>
                                            <option value=""></option>
                                            {
                                                Ratings.map((item, i) => (
                                                    <option key={item}>{item}</option>
                                                ))
                                            }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label style={bold}>Stimulation Needs</label>
                                <div className="controls">
                                    <select
                                        name="stimulationNeeds"
                                        id="stimulationNeeds"
                                        className="form-select mb-3" 
                                        onChange={handleStimulationNeeds} 
                                        value={stimulationNeeds}>
                                            <option value=""></option>
                                            {
                                                Ratings.map((item, i) => (
                                                    <option key={item}>{item}</option>
                                                ))
                                            }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-1 col-md-offset-1">
                            <button type="button" id="save" onClick={handleSave} className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        }
        </>
     );
}
 
export default Edit;