import React, { useState } from 'react';
import Breeds from "./Breeds";
import List from './List';
import Ratings from './Ratings';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Form = () => {
    const [breed, setBreeds] = useState('');
    const [description, setDescription] = useState('');
    const [gallery, setGallery] = useState('');
    const [text, setText] = useState();
    const [allText, setAllText] = useState([]);
    const [question, setQuestion] = useState();
    const [allQuestions, setAllQuestions] = useState([]);
    const [response, setResponse] = useState();
    const [allResponses, setAllResponses] = useState([]);
    const [source, setSource] = useState();
    const [allSources, setAllSources] = useState([]);
    const [link, setLink] = useState();
    const [allLinks, setAllLinks] = useState([]);
    const [textSimilarBreed, setTextSimilarBreed] = useState();
    const [allSimilarBreedText, setAllSimilarBreedText] = useState([]);
  
   
    const [minHeight, setMinHeight] = useState();
    const [maxHeight, setMaxHeight] = useState();
    const [minWeight, setMinWeight] = useState();
    const [maxWeight, setMaxWeight] = useState();
    const [minLifeSpan, setMinLifeSpan] = useState();
    const [maxLifeSpan, setMaxLifeSpan] = useState();

    const [affection, setAffection] = useState();
    const [children, setChildren] = useState();
    const [otherdogs, setOtherDogs] = useState();

    const [shedding, setShedding] = useState();
    const [grooming, setGrooming] = useState();
    const [drooling, setDrooling] = useState();

    const [strangers, setStrangers] = useState();
    const [playful, setPlayful] = useState();
    const [protective, setProtective] = useState();
    const [adaptability, setAdaptability] = useState();

    const [trainability, setTrainability] = useState();
    const [energyLevel, setEnergyLevel] = useState();
    const [barkingLevel, setBarkingLevel] = useState();
    const [stimulationNeeds, setStimulationNeeds] = useState();

    const handleAddNewQuestion = () => {
       
        if(typeof question !== 'undefined' && question.length > 0){
            setAllQuestions([...allQuestions, question.replace(/[\n\r]+/g, '')]);
            setQuestion('');
        }else{
            return false;
        }
    }

    const handleAddNewResponse = () => {
        if(typeof response !== 'undefined' && response.length > 0){
            setAllResponses([...allResponses, response.replace(/[\n\r]+/g, '')]);
            setResponse('');
        }else{
            return false;
        }
    }

    const handleAddNewSource = () => {
        if(typeof source !== 'undefined' && source.length > 0){
            setAllSources([...allSources, source.replace(/[\n\r]+/g, '')]);
            setSource('');
        }else{
            return false;
        }
    }

    const handleAddNewLink = () => {
        if(typeof link !== 'undefined' && link.length > 0){
            setAllLinks([...allLinks, link.replace(/[\n\r]+/g, '')]);
            setLink('');
        }else{
            return false;
        }
    }
    
    const reset = () => {
        setAllText([]);
        setAllQuestions([]);
        setAllResponses([]);
        setAllSources([]);
        setAllLinks([]);
        setAllSimilarBreedText([]);
        setStimulationNeeds([]);
        setBarkingLevel(0);
        setEnergyLevel(0);
        setTrainability(0);
        setProtective(0);
        setPlayful(0);
        setStrangers(0);
        setDrooling(0);
        setGrooming(0);
        setShedding(0);
        setOtherDogs(0);
        setChildren(0);
        setAffection(0);
        setMaxLifeSpan(0);
        setMinLifeSpan(0);
        setMaxWeight(0);
        setMinWeight(0);
        setMaxHeight(0);
        setMinHeight(0);
        setDescription('');
        setBreeds('');
        setGallery('');
    }

    const handleSave = (event) => {
        event.preventDefault();
        const data = {
            breed : breed,
            description : description,
            gallery : gallery,
            allText : allText,
            allQuestions : allQuestions,
            allResponses : allResponses,
            allSources : allSources,
            allLinks : allLinks,
            allSimilarBreedText : allSimilarBreedText,
            minHeight : minHeight,
			maxHeight : maxHeight,
			minWeight: minWeight,
			maxWeight : maxWeight,
            minLifeSpan : minLifeSpan,
			maxLifeSpan : maxLifeSpan,
			affection : affection,
            children : children,
            otherdogs : otherdogs,
            shedding : shedding,
            grooming : grooming,
            drooling : drooling,
            strangers : strangers,
            playful : playful,
            protective : protective,
            adaptability : adaptability,
            trainability : trainability,
            energyLevel : energyLevel,
            barkingLevel : barkingLevel,
            stimulationNeeds : stimulationNeeds,
        };
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/v2/add.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
                reset();
            }
        })
        .catch(err => {
            console.log(err);
        });
    };

    const bold = {
        fontWeight : '600'
    }

    const handleBreed = event => {
        const breed = event.target.value;
        setBreeds(event.target.value);
        if(breed.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleMinHeight = event => {
        setMinHeight(event.target.value);
    }

    const handleMaxHeight = event => {
        setMaxHeight(event.target.value);
    }

    const handleMinWeight = event => {
        setMinWeight(event.target.value);
    }

    const handleMaxWeight = event => {
        setMaxWeight(event.target.value);
    }

    const handleMinLifeSpan = event => {
        setMinLifeSpan(event.target.value);
    }

    const handleMaxLifeSpan = event => {
        setMaxLifeSpan(event.target.value);
    }

    const handleAffection = event => {
        setAffection(event.target.value);
    }
    const handleChildren = event => {
        setChildren(event.target.value);
    }
    const handleOtherDogs = event => {
        setOtherDogs(event.target.value);
    }

    const handleShedding = event => {
        setShedding(event.target.value);
    }
    const handleGrooming = event => {
        setGrooming(event.target.value);
    }
    const handleDrooling = event => {
        setDrooling(event.target.value);
    }

    const handleStrangers = event => {
        setStrangers(event.target.value);
    }
    const handlePlayful = event => {
        setPlayful(event.target.value);
    }
    const handleProtective = event => {
        setProtective(event.target.value);
    }
    const handleAdaptability = event => {
        setAdaptability(event.target.value);
    }

    const handleTrainability = event => {
        setTrainability(event.target.value);
    }
    const handleEnergyLevel = event => {
        setEnergyLevel(event.target.value);
    }
    const handleBarkingLevel = event => {
        setBarkingLevel(event.target.value);
    }
    const handleStimulationNeeds = event => {
        setStimulationNeeds(event.target.value);
    }

    const handleText = event => {
        setText(event.target.value);
        if(event.target.value.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleQuestion = event => {
        setQuestion(event.target.value);
        if(event.target.value.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleResponse = event => {
        setResponse(event.target.value);
        if(event.target.value.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleSimilarText = event => {
        setTextSimilarBreed(event.target.value);
        if(event.target.value.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    }

    const handleAddNewText = () => {
        setAllText([...allText, text]);
        setText('');
    }

    const handleAddNewSimilarText = () => {
        setAllSimilarBreedText([...allSimilarBreedText, textSimilarBreed]);
        setTextSimilarBreed('');
    }

    const deleteText = (i) => {
        allText.splice(i, 1);
        setAllText([...allText]);
    }

    const deleteQuestion = (i) => {
        allQuestions.splice(i, 1);
        setAllQuestions([...allQuestions]);
    }

    const deleteResponse = (i) => {
        allResponses.splice(i, 1);
        setAllResponses([...allResponses]);
    }

    const deleteSource = (i) => {
        allSources.splice(i, 1);
        setAllSources([...allSources]);
    }

    const deleteLink = (i) => {
        allLinks.splice(i, 1);
        setAllLinks([...allLinks]);
    }

    const deleteSimilarText = (i) => {
        allSimilarBreedText.splice(i, 1);
        setAllSimilarBreedText([...allSimilarBreedText]);
    }

    const handleDescription = event => {
        const desc = event.target.value;
        setDescription(desc);
            
        if(desc.length < 10){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleGallery = event => {
        const url = event.target.value;
        setGallery(url);
            
        if(url.length < 10){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    return ( 
        <div className="container-fluid">
            <ToastContainer />
            <form style={{ marginTop:'30px' }}>
                <div className="row">
                    <div className="col">
                        <div className="form-group mb-3">
                            <label style={bold}>Dog Breeds <span className="text-danger">*</span></label>
                            <div className="controls">
                                <select
                                    name="breed"
                                    id="breed"
                                    className="form-select mb-3" 
                                    onChange={handleBreed} 
                                    value={breed}>
                                        <option value="">Select the dog breed</option>
                                        {
                                            Breeds.map((item, i) => (
                                                <option value={item} key={i}>
                                                        {item}
                                                </option>
                                            ))
                                        }
                                </select>
                                <div className="invalid-feedback">The breed field is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                        {
                        breed !== '' ? 
                        <>
                        <div className="form-group mb-3">
                            <label style={bold}>Description <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" id="description" value={description} onChange={handleDescription}/>
                            <div className="invalid-feedback">The description field is required (Min 10 characters)</div>
                            <div className="valid-feedback"></div>
                        </div>
                        <div className="form-group mb-3">
                            <label style={bold}>Gallery URL <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" id="gallery" value={gallery} onChange={handleGallery}/>
                            <div className="invalid-feedback">The gallery URL field is required</div>
                            <div className="valid-feedback"></div>
                        </div>
                        </>
                        :
                        ''
                        }
                    </div>
                </div>
                
                {
                breed !== '' ? 
                <div className="row">
                    <div className="col">
                        <ol style={{ 'fontSize':'13px' }}>
                            {
                                allText.map((item, i) => (
                                    <li key={i}><span>{item}</span><span className="delete" onClick={() => deleteText(i)}>&nbsp;</span></li>
                                ))
                            }
                        </ol>
                    </div>
                </div>
                :''
                }
                {
                breed !== '' ? 
                <div className="row">
                    <div className="col">
                        <div>
                            <div className="form-group mb-3">
                                <label  style={bold}>Text {allText.length + 1}</label>
                                <textarea className="form-control" id="text" rows="1" value={text} onChange={handleText}></textarea>
                            </div>
                            <div>
                                <button type="button" id="addText" onClick={handleAddNewText} className="btn btn-primary">Add Text {allText.length + 1}</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                </div>
                : ''
                }
                {
                breed !== '' ? 
                <div style={{ background :'rgb(204 204 204 / 39%)' }}>
                    <div className="row">
                        <div className="col">
                            <div>
                                <div className="form-group mb-3">
                                    <label  style={bold}>Question {allQuestions.length + 1}</label>
                                    <textarea className="form-control" id="text" rows="1" value={question} onChange={handleQuestion}></textarea>
                                </div>
                                <div>
                                    <button type="button" id="addQuestion" onClick={handleAddNewQuestion} className="btn btn-primary">Add question {allQuestions.length + 1}</button>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ol style={{ 'fontSize':'13px' }}>
                                {
                                    allQuestions.map((item, i) => (
                                        <li key={i}><span>{item}</span><span className="delete" onClick={() => deleteQuestion(i)}>&nbsp;</span></li>
                                    ))
                                }
                            </ol>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col">
                            <div>
                                <div className="form-group mb-3">
                                    <label  style={bold}>Answer {allResponses.length + 1}</label>
                                    <textarea className="form-control" id="text" rows="1" value={response} onChange={handleResponse}></textarea>
                                </div>
                                <div>
                                    <button type="button" id="addResponse" onClick={handleAddNewResponse} className="btn btn-primary">Add answer {allResponses.length + 1}</button>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ol style={{ 'fontSize':'13px' }}>
                                {
                                    allResponses.map((item, i) => (
                                        <li key={i}><span>{item}</span><span className="delete" onClick={() => deleteResponse(i)}>&nbsp;</span></li>
                                    ))
                                }
                            </ol>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div>
                                <div className="form-group mb-3">
                                    <label  style={bold}>Source {allSources.length + 1}</label>
                                    <textarea className="form-control" id="text" rows="1" value={source} onChange={(e) => setSource(e.target.value)}></textarea>
                                </div>
                                <div>
                                    <button type="button" id="addQuestion" onClick={handleAddNewSource} className="btn btn-primary">Add source {allSources.length + 1}</button>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ol style={{ 'fontSize':'13px' }}>
                                {
                                    allSources.map((item, i) => (
                                        <li key={i}><span>{item}</span><span className="delete" onClick={() => deleteSource(i)}>&nbsp;</span></li>
                                    ))
                                }
                            </ol>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div>
                                <div className="form-group mb-3">
                                    <label  style={bold}>Add Source URL {allLinks.length + 1}</label>
                                    <textarea className="form-control" id="text" rows="1" value={link} onChange={(e) => setLink(e.target.value)}></textarea>
                                </div>
                                <div>
                                    <button type="button" id="addQuestion" onClick={handleAddNewLink} className="btn btn-primary">Add Source URL {allLinks.length + 1}</button>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ol style={{ 'fontSize':'13px' }}>
                                {
                                    allLinks.map((item, i) => (
                                        <li key={i}><span>{item}</span><span className="delete" onClick={() => deleteLink(i)}>&nbsp;</span></li>
                                    ))
                                }
                            </ol>
                        </div>
                    </div>
                    
                </div>
                :''
                }
                {
                    breed !== '' ?
                    <>
                    
                    <div className="row">
                        <div className="col">
                            <div>
                                <div className="form-group mb-3">
                                    <label  style={bold}>Similar Breed {allSimilarBreedText.length + 1}</label>
                                    <select
                                        name="text"
                                        id="text"
                                        className="form-select mb-3" 
                                        onChange={handleSimilarText} 
                                        value={textSimilarBreed}>
                                            <option value="">Select text</option>
                                            {
                                                Breeds.map((item, i) => (
                                                    <option value={item} key={i}>
                                                            {item}
                                                    </option>
                                                ))
                                            }
                                    </select>
                                    {/* <textarea className="form-control" id="text" rows="1" value={textSimilarBreed} onChange={handleSimilarText}></textarea>                  */}
                                </div>
                                <div>
                                    <button type="button" id="addSimilarText" onClick={handleAddNewSimilarText} className="btn btn-primary">Add Similar Breed {allSimilarBreedText.length + 1}</button>
                                </div>
                                
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ol style={{ 'fontSize':'13px' }}>
                                {
                                    allSimilarBreedText.map((item, i) => (
                                        <li key={i}><span>{item}</span><span className="delete" onClick={() => deleteSimilarText(i)}>&nbsp;</span></li>
                                    ))
                                }
                            </ol>
                        </div>
                    </div>
                    </>
                    : ''
                }
                {
                breed !== '' ?
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Min Height </label>
                            <div className="controls">
                                <select
                                    name="minheight"
                                    id="minheight"
                                    className="form-select mb-3" 
                                    onChange={handleMinHeight} 
                                    value={minHeight}>
                                        <option value=""></option>
                                        <List />
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Max Height </label>
                            <div className="controls">
                                <select
                                    name="maxheight"
                                    id="maxheight"
                                    className="form-select mb-3" 
                                    onChange={handleMaxHeight} 
                                    value={maxHeight}>
                                        <option value=""></option>
                                        <List />
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                : ''
                }
                {
                breed !== '' ?
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Min Weight</label>
                            <div className="controls">
                                <select
                                    name="minweight"
                                    id="minweight"
                                    className="form-select mb-3" 
                                    onChange={handleMinWeight} 
                                    value={minWeight}>
                                        <option value=""></option>
                                        <List />
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Max Weight</label>
                            <div className="controls">
                                <select
                                    name="maxweight"
                                    id="maxweight"
                                    className="form-select mb-3" 
                                    onChange={handleMaxWeight} 
                                    value={maxWeight}>
                                        <List />
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                : ''
                }
                {
                breed !== '' ?
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Min Life Span</label>
                            <div className="controls">
                                <select
                                    name="minLifeSpan"
                                    id="minLifeSpan"
                                    className="form-select mb-3" 
                                    onChange={handleMinLifeSpan} 
                                    value={minLifeSpan}>
                                        <option value=""></option>
                                        <List />
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Max Life Span</label>
                            <div className="controls">
                                <select
                                    name="maxLifeSpan"
                                    id="maxLifeSpan"
                                    className="form-select mb-3" 
                                    onChange={handleMaxLifeSpan} 
                                    value={maxLifeSpan}>
                                        <option value=""></option>
                                        <List />
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                : ''
                }

                {
                breed !== '' ?
                <>
                <div className="characteristic_title">Family Life</div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Affection</label>
                            <div className="controls">
                                <select
                                    name="affection"
                                    id="affection"
                                    className="form-select mb-3" 
                                    onChange={handleAffection} 
                                    value={affection}>
                                        <option value=""></option>
                                        {
                                            Ratings.map((item, i) => (
                                                <option key={item}>{item}</option>
                                            ))
                                        }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Children</label>
                            <div className="controls">
                                <select
                                    name="children"
                                    id="children"
                                    className="form-select mb-3" 
                                    onChange={handleChildren} 
                                    value={children}>
                                        <option value=""></option>
                                        {
                                            Ratings.map((item, i) => (
                                                <option key={item}>{item}</option>
                                            ))
                                        }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Other dogs</label>
                            <div className="controls">
                                <select
                                    name="otherdogs"
                                    id="otherdogs"
                                    className="form-select mb-3" 
                                    onChange={handleOtherDogs} 
                                    value={otherdogs}>
                                        <option value=""></option>
                                        {
                                            Ratings.map((item, i) => (
                                                <option key={item}>{item}</option>
                                            ))
                                        }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                </>
                : ''
                }

                {
                breed !== '' ?
                <>
                <div className="characteristic_title">Physical</div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Shedding</label>
                            <div className="controls">
                                <select
                                    name="shedding"
                                    id="shedding"
                                    className="form-select mb-3" 
                                    onChange={handleShedding} 
                                    value={shedding}>
                                        <option value=""></option>
                                        {
                                            Ratings.map((item, i) => (
                                                <option key={item}>{item}</option>
                                            ))
                                        }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Grooming</label>
                            <div className="controls">
                                <select
                                    name="grooming"
                                    id="grooming"
                                    className="form-select mb-3" 
                                    onChange={handleGrooming} 
                                    value={grooming}>
                                        <option value=""></option>
                                        {
                                            Ratings.map((item, i) => (
                                                <option key={item}>{item}</option>
                                            ))
                                        }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Drooling</label>
                            <div className="controls">
                                <select
                                    name="drooling"
                                    id="drooling"
                                    className="form-select mb-3" 
                                    onChange={handleDrooling} 
                                    value={drooling}>
                                        <option value=""></option>
                                        {
                                            Ratings.map((item, i) => (
                                                <option key={item}>{item}</option>
                                            ))
                                        }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                </>
                : ''
                }

                {
                breed !== '' ?
                <>
                <div className="characteristic_title">Social</div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Strangers</label>
                            <div className="controls">
                                <select
                                    name="strangers"
                                    id="strangers"
                                    className="form-select mb-3" 
                                    onChange={handleStrangers} 
                                    value={strangers}>
                                        <option value=""></option>
                                        {
                                            Ratings.map((item, i) => (
                                                <option key={item}>{item}</option>
                                            ))
                                        }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Playful</label>
                            <div className="controls">
                                <select
                                    name="playful"
                                    id="playful"
                                    className="form-select mb-3" 
                                    onChange={handlePlayful} 
                                    value={playful}>
                                        <option value=""></option>
                                        {
                                            Ratings.map((item, i) => (
                                                <option key={item}>{item}</option>
                                            ))
                                        }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Protective</label>
                            <div className="controls">
                                <select
                                    name="protective"
                                    id="protective"
                                    className="form-select mb-3" 
                                    onChange={handleProtective} 
                                    value={protective}>
                                        <option value=""></option>
                                        {
                                            Ratings.map((item, i) => (
                                                <option key={item}>{item}</option>
                                            ))
                                        }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Adaptability</label>
                            <div className="controls">
                                <select
                                    name="adaptability"
                                    id="adaptability"
                                    className="form-select mb-3" 
                                    onChange={handleAdaptability} 
                                    value={adaptability}>
                                        <option value=""></option>
                                        {
                                            Ratings.map((item, i) => (
                                                <option key={item}>{item}</option>
                                            ))
                                        }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                </>
                : ''
                }

                {
                breed !== '' ?
                <>
                <div className="characteristic_title">Personality</div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Trainability</label>
                            <div className="controls">
                                <select
                                    name="trainability"
                                    id="trainability"
                                    className="form-select mb-3" 
                                    onChange={handleTrainability} 
                                    value={trainability}>
                                        <option value=""></option>
                                        {
                                            Ratings.map((item, i) => (
                                                <option key={item}>{item}</option>
                                            ))
                                        }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Energy Level</label>
                            <div className="controls">
                                <select
                                    name="energyLevel"
                                    id="energyLevel"
                                    className="form-select mb-3" 
                                    onChange={handleEnergyLevel} 
                                    value={energyLevel}>
                                        <option value=""></option>
                                        {
                                            Ratings.map((item, i) => (
                                                <option key={item}>{item}</option>
                                            ))
                                        }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Barking Level</label>
                            <div className="controls">
                                <select
                                    name="barkingLevel"
                                    id="barkingLevel"
                                    className="form-select mb-3" 
                                    onChange={handleBarkingLevel} 
                                    value={barkingLevel}>
                                        <option value=""></option>
                                        {
                                            Ratings.map((item, i) => (
                                                <option key={item}>{item}</option>
                                            ))
                                        }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label style={bold}>Stimulation Needs</label>
                            <div className="controls">
                                <select
                                    name="stimulationNeeds"
                                    id="stimulationNeeds"
                                    className="form-select mb-3" 
                                    onChange={handleStimulationNeeds} 
                                    value={stimulationNeeds}>
                                        <option value=""></option>
                                        {
                                            Ratings.map((item, i) => (
                                                <option key={item}>{item}</option>
                                            ))
                                        }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                </>
                : ''
                }

                {
                breed !== '' ?
                    <div className="row justify-content-center">
                        <div className="col-md-1 col-md-offset-1">
                            <button type="button" id="save" onClick={handleSave} className="btn btn-primary">Save</button>
                        </div>
                    </div>
                : ''
                }
            </form>
        </div>
     );
}
 
export default Form;