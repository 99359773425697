const  List = () => {

    var rows = [], i = 0, len = process.env.REACT_APP_LIST;
    while (++i <= len) rows.push(i);
    return (
        <>
          {rows.map(function (i) {
            return <option key={i}>{i}</option>;
          })}
        </>
      );
}
 
export default List;